import { useState, useEffect, useLayoutEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { chk_img,img_url,gotoURL } from '../components/lang'
// import Preloader from '../components/Template/Preloader'
import api from '../components/api'
import styles from '../assets/css/home.module.css';
import bookpromotion from '../assets/book-promotion.png';
import gale from '../assets/gale.png';
import ebsco from '../assets/ebsco.png';

import Slide from '../components/Homepage/Slide'

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Home() {
    const [init, setInit] = useState([]);
	const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [detail, setDetail] = useState(null);
    const [popup, setPopup] = useState(false);
    const [width, height] = useWindowSize();
    // const [isAdmin, setIsAdmin] = useState(false);
    // const [isPending, startTransition] = useTransition();

    reveal();
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/setting');
                setInit(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
                const response = await api.get('/article/1');
                const article_rs = response.data.results;
                setArticle(article_rs);
                const detail_rs = JSON.parse(article_rs.detail);
                setDetail(detail_rs);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }

    function changeLocation(location){
        if(location === 1){
            let iframe = document.getElementById("meeting-location");
            iframe.src= "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.044843927152!2d98.9614556758331!3d18.796154760697053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a630b71f3d1%3A0x37645e447720762d!2sKantary%20Hills%20Hotel%2C%20Chiang%20Mai!5e0!3m2!1sen!2sth!4v1722486163033!5m2!1sen!2sth"
        }else if(location === 2){
            let iframe = document.getElementById("meeting-location");
            iframe.src= "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.859621215291!2d98.9523246758332!3d18.80440836044131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a651aaa1413%3A0xe3b9f47c9dacf5c7!2z4LiB4Lit4LiH4LiB4Lil4Liy4LiHIOC4quC4s-C4meC4seC4geC4h-C4suC4meC4oeC4q-C4suC4p-C4tOC4l-C4ouC4suC4peC4seC4oiDguKHguKvguLLguKfguLTguJfguKLguLLguKXguLHguKLguYDguIrguLXguKLguIfguYPguKvguKHguYg!5e0!3m2!1sen!2sth!4v1722486119121!5m2!1sen!2sth"
        }else{
            let iframe = document.getElementById("meeting-location");
            iframe.src= "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.9309799411253!2d98.9518453!3d18.801229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a6be31902eb%3A0xe62bbe02b4bcefcc!2sChiang%20Mai%20University%20Library!5e0!3m2!1sen!2sth!4v1722486034143!5m2!1sen!2sth"
        }
    }
    return (
        <>
            <div className="homepage">
                {/* {(popup===true)?
                <div className="blackscreen">
                    <div className="popup">
                        <div className="popup-bg"style={{"backgroundImage":`url(${img_url}homepage/clock-tower-cmu.jpg)`}}>&nbsp;</div>
                        <div className="popup-info" >
                            <div className="logo-img">
                                <img src={img_url+init.logo} alt={init.name} height="75.5"/>
                                <img src={img_url+init.cmu_logo} alt={init.name} height="75.5"/>
                            </div>
                            <h3>The 19th AUNILO Meeting</h3>
                            <button onClick={e=>gotoURL("/article/17")}>Register</button>
                            <button onClick={e=>gotoURL("/article/10")}>Hotel Reservation</button>
                            <p><span>*</span>All Attendees must reserve room on their own.</p>
                            <div class="close-popup" onClick={e=>setPopup(false)}>close</div>
                        </div>
                    </div>
                </div>
                :''} */}
                <div className="main-slide">
                    <img src={img_url+"homepage/slide_bg.jpg"} alt={init.name}/>
                    <div className="main-slide-info">
                        <div className="no">19</div>
                        <div className="detail">
                            <h4>th</h4>
                            <h5>AUNILO MEETING</h5>
                            <h5>THAILAND, 2024</h5>
                            <p>Enhancing Future Skills for Academic Librarians</p>
                        </div>
                    </div>
                </div>
                <div className="project-overview" id="about">
                    <div className="container logo-img">
                    <a href={process.env.PUBLIC_URL + "/"}><img src={img_url+init.logo} alt={init.name} className="h-[85px]"/></a>
                    <a href={process.env.PUBLIC_URL + "/"}><img src={img_url+init.cmu_logo} alt={init.name} className="h-[60px]"/></a>
                    <a href={process.env.PUBLIC_URL + "/"}><img src={gale} alt={"Gale"} className="h-[60px]"/></a>
                    <a href={process.env.PUBLIC_URL + "/"}><img src={bookpromotion} alt={"book-promotion"} className="h-[80px]"/></a>
                    <a href={process.env.PUBLIC_URL + "/"}><img src={ebsco} alt={"EBSCO"} className="h-[60px]"/></a>
                    </div>
                    <div className="info">
                        <p dangerouslySetInnerHTML={{__html: article?.info_th}}></p>
                        <img src={img_url+article?.cover_img} alt={init.name}/>
                    </div>
                    <div className="day-info">
                        <ul>
                            <li>
                                <img src={img_url+detail?.day_one_img} alt="Day 1"/>
                                <div>
                                <h4>Day 1 : 30 October 2024</h4>
                                <p>{detail?.day_one_info}</p>
                                </div>
                            </li>
                            <li>
                                <img src={img_url+detail?.day_two_img} alt="Day 2"/>
                                <div>
                                <h4>Day 2 : 31 October 2024</h4>
                                <p>{detail?.day_two_info}</p>
                                </div>
                            </li>
                            <li>
                                <img src={img_url+detail?.day_three_img} alt="Day 3"/>
                                <div>
                                <h4>Day 3 : 1 November 2024</h4>
                                <p>{detail?.day_three_info}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="schedule" id="schedule">
                    <div className="container" dangerouslySetInnerHTML={{__html: article?.desc_th}}>
                    </div>
                </div>
                <div className="workshop" id="workshop">
                    <div className="container">
                    <div className="title">Keynote and <br/>Workshop <br/>speakers<div className="title-underline"></div></div>
                    <div className="info">
                        <ul>
                            <li onClick={e=>gotoURL("/article/2")}>
                                <img src={img_url+"homepage/songphan.png"} alt="Assoc. Prof. Dr. Songphan Choemprayong" />
                                <p>Assoc. Prof. Dr. Songphan Choemprayong</p>
                            </li>
                            <li onClick={e=>gotoURL("/article/3")}>
                                <img src={img_url+"homepage/1722408491_410ca998325216c70ea4.jpg"} alt="Assoc. Prof. Dr. Suthida Chamrat" />
                                <p>Assoc. Prof. Dr. Suthida Chamrat</p>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="location" id="location">
                    <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.044843927152!2d98.9614556758331!3d18.796154760697053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a630b71f3d1%3A0x37645e447720762d!2sKantary%20Hills%20Hotel%2C%20Chiang%20Mai!5e0!3m2!1sen!2sth!4v1722486163033!5m2!1sen!2sth" width="100%" height="100%" style={{"border":0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" id="meeting-location" title="Meeting Location"></iframe>
                    </div>
                    <div className="info">
                        <h3>Date and Location</h3>
                        <ul>
                            <li>
                            <div className="detail">
                                <b><span>30</span> October 2024</b><span className="link" onClick={e=>changeLocation(1)}> Hotel Kantary Hills, Chiang Mai</span>
                            </div>
                            </li>
                            <li>
                            <div className="detail">
                                <b><span>31</span> October 2024</b><span className="link" onClick={e=>changeLocation(1)}> Hotel Kantary Hills, Chiang Mai</span> / <span className="link" onClick={e=>changeLocation(3)}> Chiang Mai University Library</span>
                            </div>
                            </li>
                            <li>
                            <div className="detail">
                                <b><span>1</span> November 2024</b>Networking activities & Chiang Mai Experience
                            </div>
                            </li>
                        </ul>
                        <div className="rsvn-btn"><a href="https://aunilo.library.cmu.ac.th/api/media/pdf/Cantary-Room-Resevation.pdf" target="_blank" rel="noreferrer">Reservation Form</a></div>
                    </div>
                </div>
            </div>
        </>
    )
}